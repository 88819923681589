import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

const Description = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & strong {
    color: ${({ theme }) => theme.color.text.light};
  }
`

const ProcessImage = styled(Image)`
  width: 100%;

  @media (min-width: 992px) {
    height: 100%;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;
`

const Background = styled.div`
  z-index: 0;
  left: 0;
  top: 0;
  overflow: hidden;
`

const BackgroundWrapper = styled.div`
  height: 850px;
  overflow: hidden;

  @media (min-width: 1200px) {
    top: 225px;
  }
`

const BackgroundOverlay = styled.div`
  background: rgb(51, 42, 124);

  @media (min-width: 1200px) {
    background: radial-gradient(
      circle,
      rgba(51, 42, 124, 0.2987570028011205) 25%,
      rgba(51, 42, 124, 1) 67%
    );
  }

  @media (max-width: 1199.98px) {
    background: radial-gradient(
      circle,
      rgba(51, 42, 124, 0.2987570028011205) 25%,
      rgba(51, 42, 124, 1) 50%
    );
  }

  left: 0;
  top: 0;
  z-index: 2;
`

const BackgroundImage = styled(Image)`
  left: -50px;
  top: -50px;
  width: calc(100% + 100px);
  height: calc(100% + 100px);
  z-index: 1;
`

interface ProcessProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Process
}

const Process: React.FC<ProcessProps> = ({ fields }) => (
  <section className="color-background-purple">
    <div className="container py-5 position-relative">
      <Background className="position-absolute w-100 h-100 d-lg-block d-none">
        <div className="row justify-content-end">
          <div className="col-8">
            <BackgroundWrapper className="position-relative">
              <BackgroundOverlay className="position-absolute w-100 h-100" />
              <BackgroundImage
                className="position-absolute"
                image={fields?.backgroundimage}
              />
            </BackgroundWrapper>
          </div>
        </div>
      </Background>

      <Content className="row justify-content-center">
        <div className="col-10">
          <Description content={fields?.description} />
        </div>
      </Content>

      <Content className="row mt-5">
        <div className="col-md-10 col-lg-7 col-xl-7 pr-xl-5">
          <ProcessImage image={fields.image} />
        </div>
      </Content>
    </div>
  </section>
)

export default Process
